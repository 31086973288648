import {Routes} from '@angular/router'
import {deadGuard} from './application/auth.guard'
import {LoginComponent} from './common/login/login.component'
import {InputComponent} from './kyc-minor/input/input.component'

export const routes: Routes = [
  {
    path: 'minderarig',
    loadChildren: () => import('./kyc-minor/kyc-minor.routes')
  },
  {
    path: 'foralder',
    loadChildren: () => import('./guardian/guardian.routes')
  },
  {
    path: 'kyc/login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [deadGuard],
    component: InputComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    canActivate: [deadGuard],
    component: InputComponent,
    pathMatch: 'full'
  }
]
