import {Component} from '@angular/core'
import {FooterComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import version from '../assets/package.json'
import { HeaderComponent } from './common/header/header.component'
import { RouterOutlet } from '@angular/router'

@Component({
    selector: 'spb-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
  imports: [HeaderComponent, RouterOutlet, FooterComponent]
})
export class AppComponent {
  public version = version.version
  public copyrightYears = `2020-${new Date().getFullYear()}`
}
