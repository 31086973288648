import {Component} from '@angular/core'
import {LogoComponent} from '@sparbanken-syd/sparbanken-syd-theme'

@Component({
    selector: 'spb-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [
        LogoComponent
    ]
})
export class HeaderComponent {
}
